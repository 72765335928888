// Core
import cx from "classnames";
import React, { useCallback, useMemo } from "react";

// Components
import { Image } from "components/ui/Image";
import { Title } from "components/ui/Typography/Title";
import { Text } from "components/ui/Typography/Text";
import { Col, Grid, Row } from "antd";
import { Icon } from "components/ui/Icon";
import { Container } from "components/common/Container";

// Hooks
import { useUserCountry } from "bus/user/hooks/useUserCountry";
import { useTranslation } from "client/utils/i18n/client";

// Utils
import { introProximity } from "data/intro";
import { rowGutters } from "utils/constants";

import st from "./styles.module.css";

export const IntroProximity = () => {
  const { t } = useTranslation();
  const { userCountry } = useUserCountry();
  const { md = true, lg = true } = Grid.useBreakpoint();

  const isMobile = !md && !lg;
  const isTablet = !lg && !isMobile;
  const isDesktop = !isTablet && !isMobile;

  const getClassnames = useCallback(
    (className: string): string => {
      return cx(st[className], {
        [st.mobile]: isMobile,
        [st.tablet]: isTablet,
      });
    },
    [isTablet, isMobile],
  );

  const titleJSX = t(introProximity.title);
  const subtitleJSX = t(introProximity.subtitle);

  const mapOptions = (options: { icon: string; description: string }[]) => {
    return options.map(({ icon, description }) => {
      return (
        <Row gutter={rowGutters} key={description} className={getClassnames("option")}>
          <Col>
            <Icon name={icon} className={getClassnames("icon")} />
          </Col>
          <Col>
            <Text size="14" color="gray-700">
              {t(description)}
            </Text>
          </Col>
        </Row>
      );
    });
  };

  const accountOptions = useMemo(() => {
    return mapOptions(introProximity.options.account.items);
  }, [isTablet, isMobile, t]);

  const serviceOptions = useMemo(() => {
    return mapOptions(introProximity.options.service.items);
  }, [isTablet, isMobile, t]);

  const accountOptionsTitleJSX = t(introProximity.options.account.title);
  const serviceOptionsTitleJSX = t(introProximity.options.service.title);
  const showServiceOptionLabel = userCountry?.toUpperCase() === "DE";

  const contentOffset = (isDesktop && 8) || (isTablet && 12) || 0;

  return (
    <section className={getClassnames("proximity")}>
      <div className={getClassnames("image-container")}>
        <Image
          src={introProximity.img.src}
          alt={t(introProximity.img.alt)}
          placeholder="blur"
          layout="fill"
          objectFit="cover"
        />
      </div>
      <Container>
        <Row gutter={rowGutters}>
          <Col offset={contentOffset} className={getClassnames("content-container")}>
            <div className={st.content}>
              {isDesktop && (
                <>
                  <div className={getClassnames("title")}>
                    <Title size="36" tag="h2" font="second" fontWeight="700">
                      {titleJSX}
                    </Title>
                  </div>
                  <Title tag="h5" size="16" color="gray-700">
                    {subtitleJSX}
                  </Title>
                </>
              )}
              {isTablet && (
                <>
                  <div className={getClassnames("title")}>
                    <Title size="30" tag="h2" font="second" fontWeight="700">
                      {titleJSX}
                    </Title>
                  </div>
                  <Title tag="h5" size="16" color="gray-700">
                    {subtitleJSX}
                  </Title>
                </>
              )}
              {isMobile && (
                <>
                  <div className={getClassnames("title")}>
                    <Title
                      size="18"
                      tag="h2"
                      font="second"
                      fontWeight="700"
                      alignmentHorizontal="center"
                    >
                      {titleJSX}
                    </Title>
                  </div>
                  <Title tag="h5" alignmentHorizontal="center" size="14" color="gray-700">
                    {subtitleJSX}
                  </Title>
                </>
              )}
              <Row gutter={rowGutters} className={getClassnames("options-container")}>
                <Col sm={24} xs={24} lg={12}>
                  <div className={getClassnames("options-title")}>
                    {isMobile && (
                      <Title fontWeight="600" size="16" alignmentHorizontal="center" tag="h3">
                        {serviceOptionsTitleJSX}
                      </Title>
                    )}
                    {isTablet && (
                      <Title fontWeight="600" size="18" tag="h3">
                        {serviceOptionsTitleJSX}
                      </Title>
                    )}
                    {isDesktop && (
                      <Title size="24" tag="h3">
                        {serviceOptionsTitleJSX}
                      </Title>
                    )}
                    {showServiceOptionLabel && (
                      <div className={getClassnames("options-label")}>
                        <Text size="14" fontWeight="600" color="white-100">
                          {t(introProximity.options.service.label)}
                        </Text>
                      </div>
                    )}
                  </div>
                  <div className={getClassnames("options")}>{serviceOptions}</div>
                </Col>
                <Col sm={24} xs={24} lg={12}>
                  <div className={getClassnames("options-title")}>
                    {isMobile && (
                      <Title fontWeight="600" size="16" alignmentHorizontal="center" tag="h3">
                        {accountOptionsTitleJSX}
                      </Title>
                    )}
                    {isTablet && (
                      <Title fontWeight="600" size="18" tag="h3">
                        {accountOptionsTitleJSX}
                      </Title>
                    )}
                    {isDesktop && (
                      <Title size="24" tag="h3">
                        {accountOptionsTitleJSX}
                      </Title>
                    )}
                  </div>
                  <div className={getClassnames("options")}>{accountOptions}</div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
